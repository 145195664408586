<template>
    <div class="container">
      <div class="row mt-5">
        <div class="col-12">
          <h1>{{title}}</h1>
          <h3>{{meetingTitle}}</h3>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <button class="btn " @click="getSignature" v-if="!loading">Join Meeting</button>
          <button disabled v-else>Preparing...</button>
        </div>
      </div>
    </div>
</template>

<script>
import axios from "axios";
import CryptoJS from "crypto-js";
export default {
  name: "ZoomMeeting",
  async created() {
    this.loading = await true;
    let urlParams = await new URLSearchParams(window.location.search);
    let tk = await urlParams.get("tk");
    let meeting_id = await urlParams.get("meeting_id");
    if (typeof tk != "undefined" && typeof meeting_id != "undefined") {
      await this.getEventDetail(meeting_id, tk);
    }

    await this.ZoomMtg.setZoomJSLib("https://source.zoom.us/2.8.0/lib", "/av");
    await this.ZoomMtg.preLoadWasm();
    await this.ZoomMtg.prepareWebSDK();
    // loads language files, also passes any error messages to the ui
    await this.ZoomMtg.i18n.load("en-US");
    await this.ZoomMtg.i18n.reload("en-US");
    this.loading = await false;
  },
  mounted() {
    this.ZoomMtg.inMeetingServiceListener("onUserJoin", data => {
      console.log("inMeetingServiceListener onUserJoin", data);
    });
  },
  data() {
    return {
      loading: false,
      // title: "Zoom Meeting",
      title: process.env.VUE_APP_TITLE,
      // apiKey: "WQtUkQjdRSqrN2vMtaXzLw",
      apiKeyCipher: process.env.VUE_APP_API_KEY_CIPHER,
      apiSecretCipher: process.env.VUE_APP_SECRET_KEY_CIPHER,
      leaveUrl: process.env.VUE_APP_LEAVE_URL,
      meetingTitle: "",
      meetingNumber: "",
      passWord: "",
      role: 0,
      signatureEndpoint:
        process.env.VUE_APP_SIGNATURE_ENDPOINT + "/zoom-signature",
      apiEndpoint: process.env.VUE_APP_API_ENDPOINT,
      userEmail: "",
      userName: "",
      // pass in the registrant's token if your meeting or webinar requires registration. More info here:
      // Meetings: https://marketplace.zoom.us/docs/sdk/native-sdks/web/build/meetings/join#join-registered
      // Webinars: https://marketplace.zoom.us/docs/sdk/native-sdks/web/build/webinars/join#join-registered-webinar
      registrantToken: ""
    };
  },
  methods: {
    getEventDetail(meetingId, tk) {
      axios
        .get(
          this.apiEndpoint +
            "/nd-tracking/v1/event/get-zoom-detail?meeting_id=" +
            meetingId +
            "&tk=" +
            tk
        )
        .then(res => {
          var { data } = res;
          // console.log("dataaaa", data.data);
          this.meetingTitle = data.data.event_title;
          this.meetingNumber = meetingId;
          this.passWord = data.data.zoom_event_password;
          this.userEmail = data.data.member_email;
          this.userName = data.data.member_name;
          this.registrantToken = tk;
          // this.leaveUrl =
          //   process.env.VUE_APP_LEAVE_URL +
          //   "/?tk=" +
          //   tk +
          //   "&meeting_id=" +
          //   meetingId;
          this.leaveUrl =
          `${process.env.VUE_APP_LEAVE_URL}?meeting_id=${meetingId}&tk=${tk}`;
          // console.log("thismeetingTitle", this.meetingTitle);
          // console.log(res);
        })
        .catch(error => {
          console.log(error);
        });
    },
    decrypt(cipherText) {
      // Decrypt
      var bytes = CryptoJS.AES.decrypt(
        cipherText,
        process.env.VUE_APP_CIPHER_KEY
      );
      var originalText = bytes.toString(CryptoJS.enc.Utf8);
      // console.log("origi: ", originalText);
      return originalText;
    },
    getSignature() {
      axios
        .post(this.signatureEndpoint, {
          meetingNumber: this.meetingNumber,
          role: this.role,
          apiKeyCipher: this.apiKeyCipher,
          apiSecretCipher: this.apiSecretCipher
        })
        .then(res => {
          console.log(res.data.signature);
          this.startMeeting(res.data.signature);
        })
        .catch(error => {
          console.log(error);
        });
    },
    startMeeting(signature) {
      document.getElementById("zmmtg-root").style.display = "block";

      this.ZoomMtg.init({
        leaveUrl: this.leaveUrl,
        success: success => {
          console.log(success);
          this.ZoomMtg.join({
            meetingNumber: this.meetingNumber,
            userName: this.userName,
            signature: signature,
            apiKey: this.decrypt(this.apiKeyCipher),
            userEmail: this.userEmail,
            passWord: this.passWord,
            tk: this.registrantToken,
            success: success => {
              console.log(success);
            },
            error: error => {
              console.log(error);
            }
          });
        },
        error: error => {
          console.log(error);
        }
      });
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
html, body {
height: 100%;
width: 100%;
/* min-height: 643px; */
min-width: 100% !important;
overflow: auto !important;
font-size: 16px;
padding: 0;
margin: 0;
font-family: "Open Sans";
color: #4a4a4a;
}
main {
  width: 70%;
  margin: auto;
  text-align: center;
}

.btn {
  margin-top: 20px;
  background-color: #2d8cff;
  color: #ffffff;
  text-decoration: none;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 40px;
  padding-right: 40px;
  display: inline-block;
  border-radius: 10px;
  cursor: pointer;
  border: none;
  outline: none;
}

.btn:hover {
  background-color: #2681f2;
}
</style>
