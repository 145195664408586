<template>
  <div id="app">
    <Zoom />
  </div>
</template>

<script>
// import HelloWorld from './components/HelloWorld.vue'
import Zoom from "./components/zoom.vue";

export default {
  name: "App",
  components: {
    Zoom
    }
};

</script>

<style>
#app {
  width: 70%;
  margin: auto;
  text-align: center;
}
#zmmtg-root {
  display: none;
}
@media only screen and (max-width: 1024px) {
#app {
  text-align: center;
  height: 100vh;
}
}
</style>
